<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="indigo lighten-5" flat>
        <BackButton />
        <v-tabs v-model="currentTab">
          <v-tab v-for="tab in tabs" :key="tab.title">
            {{ tab.title }}
          </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <div v-if="currentTab === 0">
          <v-btn @click="$router.push({ name: 'PushNotificationSettings' })" color="primary" small>створити правило</v-btn>
        </div>
      </v-toolbar>

      <v-tabs-items v-model="currentTab">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <v-card flat color="blue-grey lighten-5">
            <component :is="item.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import BackButton from '@/components/common/BackButton'

export default {
  name: 'PushNotificationMain',

  components: {
    BackButton,
    InstantNotification: () => import('@/components/support/push_notification/InstantNotification'),
    AutoNotifications: () => import('@/components/support/push_notification/AutoNotifications'),
    ProgressCircular: () => import('@/components/common/ProgressCircular'),
  },

  data: () => ({
    currentTab: 0,
    tabs: [
      {
        title: 'Автоматичні повідомлення',
        component: 'AutoNotifications',
      },
      {
        title: 'Миттєве повідомлення',
        component: 'InstantNotification',
      },
    ],
  }),
}
</script>

<style scoped lang="scss"></style>
